import { auth, db, storage } from "@/firebase";
import { doc, collection, getDocs } from "firebase/firestore";
import { getDownloadURL, ref, getBlob } from "firebase/storage";

const checkUpgradable = (project) => {
  let serviceInfo = project.serviceInfo;
  if (!serviceInfo.upgrade || serviceInfo.upgrade === "false") {
    if (serviceInfo.serviceType === "Gen-Health") {
      return true;
    }
  }
  return false;
};

export const getUploadHistory = async (user_id, category) => {
  try {
    let uploadHistory = [];

    const userDocRef = doc(db, "users", user_id);
    const submissionsCollectionRef = collection(userDocRef, "submissions"); // Access the submissions subcollection
    const submissionsSnapshot = await getDocs(submissionsCollectionRef);

    const submissions = submissionsSnapshot.docs.map((doc) => doc.data());
    for (const submission of submissions) {
      if (category && submission.category != category) {
        continue;
      }
      const userStorageRef = ref(storage, submission.serviceInfo.path + "/patientInfo.json");
      const patientInfoBlob = await getBlob(userStorageRef);
      const patientInfo = await new Response(patientInfoBlob).json();
      let reportInfo = {};
      if (patientInfo.reportInfo) {
        for (const report of patientInfo.reportInfo.reportList) {
          if (report.reportType !== submission.serviceInfo.serviceType) {
            continue;
          }
          
          await getDownloadURL(ref(storage, submission.serviceInfo.path + "/" + report.filename)).then((url) => {
            reportInfo = {
              path: url,
              endtimestep: report.endtimestep,
              reportType: report.reportType,
            }
          }
          ).catch((error) => {
            console.error(error);
          });
        }
      }

      const submissionTimestamp = new Date(submission.timestamp.seconds * 1000);
      const updatedInfo = {
        name: submission.firstname + " " + submission.lastname,
        sampleID: submission.sampleID ? submission.sampleID : "N/A",
        date:
          submissionTimestamp.toLocaleDateString() + " " + submissionTimestamp.toLocaleTimeString(),
        status: (reportInfo.path) ? "Completed" : "Processing",
        reportLink: (reportInfo.path) ? reportInfo.path : "",
        canUpgrade: checkUpgradable(submission),
      };
      const uploadElement = {
        ...submission,
        ...updatedInfo,
      };
      uploadHistory.push(uploadElement);
    }
    uploadHistory.sort((a, b) => b.timestamp - a.timestamp);
    console.log(uploadHistory);
    return uploadHistory;
  } catch (error) {
    console.error(error);
    return [];
  }
};
