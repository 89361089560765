<template>
    <div>
        <SideBar />
        <DataSubmitAreaNav />
        <form class="feedback-wrapper" novalidate>
            <div class="inner-wrapper">
                <div class="feedback-area">
                <!-- <div class="container"> -->
                    <div class="feedback-title">
                        <h2>Upload Your {{ selectedDataTypeTitle }} for {{ selectedService }}</h2>
                        <h6>Credits Required Per Submission: <span class="red">{{ selectedServiceCredits }}</span></h6>
                    </div> 
                    <div v-if="selectedService==='Gen-Decoder'">
                        <div class="project-data-upload">
                            <div class="control form-layout">
                                <div class="form-outer">
                                    <div class="form-body">
                                        <div class="form-fieldset">
                                            <div class="fieldset-heading">
                                                <h4>Gen-Decoder Type</h4>
                                                <p>You can choose Gen-Health or Gen-Pro.</p>
                                            </div>
                                            <div class="column is-6">
                                                <div class="field">
                                                    <label class="label">Service Type <span>*</span></label>
                                                    <div class="multiselect">
                                                        <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="toggleDropdown('serviceType')">
                                                            <div class="multiselect-placeholder" v-if="serviceType === ''">Service Type</div>
                                                            <div class="multiselect-single-label" v-else>
                                                                <div class="multiselect-single-label-text">{{ serviceType }}</div>
                                                            </div>
                                                            <div class="multiselect-caret">
                                                                <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                            </div>
                                                        </div>
                                                        <div class="multiselect-dropdown is-hidden" tabindex="1" id="serviceType">
                                                            <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                                <div 
                                                                    class="multiselect-option"
                                                                    role="option"
                                                                    v-for="c in serviceTypes"    
                                                                    :key="c"
                                                                    @click.stop="handleSelectServiceType(c)"
                                                                    :class="{ 'is-selected': serviceType === c }"
                                                                >
                                                                    <span>{{ c }}</span>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-fieldset">
                                            <div class="fieldset-heading">
                                                <h4>Subject Information</h4>
                                                <p>Please fill the subject information related to the submitted.</p>
                                            </div>
                                            <div class="columns is-multiline">
                                                <div class="column is-6">
                                                    <div class="field">
                                                        <label class="label">First Name <span>*</span></label>
                                                        <div class="control has-icon">
                                                            <input type="text" class="input" placeholder autocomplete="given-name" v-model="firstname" />
                                                            <iconify-icon class="iconify form-icon" icon="lucide:user"></iconify-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="column is-6">
                                                    <div class="field">
                                                        <label class="label">Last Name <span>*</span></label>
                                                        <div class="control has-icon">
                                                            <input type="text" class="input" placeholder autocomplete="family-name" v-model="lastname" />
                                                            <iconify-icon class="iconify form-icon" icon="lucide:user"></iconify-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="column is-6">
                                                    <div class="field">
                                                        <label class="label">Sex <span>*</span></label>
                                                        <div class="control has-icon">
                                                            <input type="text" class="input" placeholder="Male/Female" autocomplete="sex" v-model="sex" />
                                                            <iconify-icon class="iconify form-icon" icon="ph:gender-intersex-bold"></iconify-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="column is-6">
                                                    <div class="field">
                                                        <label class="label">Date of Birth <span>*</span></label>
                                                        <div class="control has-icon">
                                                            <input type="text" class="input" placeholder="DD/MM/YYYY" autocomplete="bday-day" v-model="dateOfBirth" />
                                                            <iconify-icon class="iconify form-icon" icon="formkit:date"></iconify-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="column is-6">
                                                    <div class="field">
                                                        <label class="label">Country/Region <span>*</span></label>
                                                        <div class="multiselect">
                                                            <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="toggleDropdown('country')">
                                                                <div class="multiselect-placeholder" v-if="country === ''">Country/Region</div>
                                                                <div class="multiselect-single-label" v-else>
                                                                    <div class="multiselect-single-label-text">{{ country }}</div>
                                                                </div>
                                                                <div class="multiselect-caret">
                                                                    <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                                </div>
                                                            </div>
                                                            <div class="multiselect-dropdown is-hidden" tabindex="1" id="country">
                                                                <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                                    <div 
                                                                        class="multiselect-option"
                                                                        role="option"
                                                                        v-for="c in countries"    
                                                                        :key="c"
                                                                        @click.stop="handleSelectCountry(c)"
                                                                        :class="{ 'is-selected': country === c }"
                                                                    >
                                                                        <span>{{ c }}</span>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="column is-6">
                                                    <div class="field">
                                                        <label class="label">Patient ID (For your track) <span>*</span></label>
                                                        <div class="control has-icon">
                                                            <input type="text" class="input" placeholder="" v-model="patientID" />
                                                            <iconify-icon class="iconify form-icon" icon="mdi:identifier"></iconify-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="column is-12">
                                                    <div class="field">
                                                        <label class="label">Family History</label>
                                                        <div class="control">
                                                            <textarea class="textarea" v-model="addtionalInfo" rows="2" placeholder="If no, leave this item empty..." autocomplete="off" autocapitalize="off" spellcheck="true"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-fieldset">
                                            <div class="fieldset-heading">
                                                <h4>Documents Upload</h4>
                                                <p>Upload all documents for this subject. {{ selectedDataTypeDescription }}</p>
                                                <p class="data-upload-warning" v-html="selectedDataTypeWarning"></p>
                                            </div>
                                            <div class="column is-12">
                                                <div class="filepond-wrap">
                                                    <div 
                                                        class="drag-area"
                                                        @dragover.prevent="dragover" 
                                                        @dragleave.prevent="dragleave" 
                                                        @drop.prevent="drop"
                                                    >
                                                        <p class="drag-section">Drag & Drop your files here.</p>
                                                        <p class="drag-section">Supported file types: {{ supportedFile }}</p>
                                                        <p class="drag-section">Approximate file size: {{ approxFileSize }}</p>
                                                        <button @click.prevent="selectFiles" class="button v-button is-light">Select Files</button>
                                                        <input type="file" multiple ref="fileInput" @change="addFilesByClickMultiple" style="display: none;" v-if="selectedDataType === 'raw'">
                                                        <input type="file" multiple ref="fileInput" @change="addFilesByClickSingle" style="display: none;" v-else>
                                                    </div>
                                                </div>
                                                <div class="link-input" v-if="selectedDataType==='raw' || selectedDataType==='mapping'">
                                                    <form @submit.prevent="addGoogleDriveLink" class="field">
                                                        <div class="field">
                                                            <label class="label">Google Drive Link (Alternative)</label>
                                                            <input
                                                                class="input"
                                                                type="text"
                                                                v-model="googleDriveLink"
                                                                placeholder="Google Drive Link"
                                                            />
                                                            <button class="button v-button is-light is-centered">Add Link</button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <table class="table dataable-table is-fullwidth">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Type</th>
                                                            <th>Size</th>
                                                            <th>Status</th>
                                                            <th v-if="!isUploading">Operation</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(file, index) in files" :key="index">
                                                            <td>
                                                                <div class="text-wrap">{{ file.name }}</div>
                                                            </td>
                                                            <td>{{ file.type }}</td>
                                                            <td>{{ (file.size / (1024 * 1024 * 1024)).toFixed(2) }} GB</td>

                                                            <td>{{ file.status }}</td>
                                                            <td v-if="!isUploading">
                                                                <button
                                                                    @click="cancelUpload(index)"
                                                                    class="button v-button is-light"
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p v-if="files.length > 0">Total Size: {{ (totalSize / (1024 * 1024 * 1024)).toFixed(2) }} GB</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <ComingSoonArea />
                    </div>
                    <div class="feedback-buttons">
                        <div class="feedback-buttons-inner">
                            <router-link class="button v-button is-light" :to="`/data-submit/data-selection?selectedService=${selectedService}&serviceType=${selectedServiceType}`">Previous</router-link>
                            <button type="button" class="button v-button is-primary" to="/data-submit" @click.prevent="submit" v-if="!isUploading && !showPopup">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- Popup for Alerts and Completion Messages -->
        <div v-if="showPopup" class="popup-overlay on-top" @click.self="closePopup">
            <div :class="['popup', popupType]">
            <button class="close" @click.prevent="closePopup">&times;</button>
            <p class="message-body">{{ popupMessage }}</p>
            </div>
        </div>
        <div v-if="isUploading" class="popup-overlay on-top" @click.self="closePopup">
            <div class="popup mixed">
            <p class="message-body">Uploading is in progress. Please do not close the broswer and keep this page activated.</p>
            </div>
        </div>
    </div>
</template>

<script>
import DataSubmitAreaNav from './DataSubmitAreaNav.vue';
import SideBar from '../dashboard/SideBar.vue';
import ComingSoonArea from './ComingSoonArea.vue';
import { countries } from '../../services/jobInfo';
import { auth, db, storage } from "@/firebase";
import {
    doc,
    setDoc,
    getDoc
} from "firebase/firestore";
import {
    ref as storageRef,
    uploadBytesResumable,
} from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";

export default {
    name: 'DataSelection',
    data() {
        return {
            userid: '',
            userCredits: 0,
            showPopup: false,
            popupMessage: '',
            popupType: '',
            serviceType: '',
            serviceTypes: ['Gen-Health', 'Gen-Pro'],
            firstname: '',
            lastname: '',
            sex: '',
            dateOfBirth: '',
            country: '',
            patientID: '',
            familyHistory: '',
            files: [],
            googleDriveLink: '',
            totalSize: 0,
            isUploading: false,
            countries,
            uploadTime: null,
            formattedUploadTime: '',
            jsonInfoObject: {},
        };
    },
    components: {
        DataSubmitAreaNav,
        SideBar,
        ComingSoonArea,
    },
    mounted() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userid = user.uid;
                const userDoc = doc(db, "users", this.userid);
                getDoc(userDoc).then((doc) => {
                    if (doc.exists()) {
                        const data = doc.data();
                        this.userCredits = data.credits;
                    }
                });
            }
            else {
                this.$router.push('/login');
            }
            this.serviceType = this.$route.query.serviceType;
        });
    },
    methods: {
        toggleDropdown(id) {
            const dropdown = document.getElementById(id);
            dropdown.classList.toggle('is-hidden');
        },

        handleSelectCountry(value) {
            if (!this.countries.includes(value)) {
                this.countries.push(value);
            }
            this.country = value;
            this.toggleDropdown("country");
        },

        handleSelectServiceType(value) {
            this.serviceType = value;
            this.toggleDropdown("serviceType");
        },


        goToLink(nextStep, key) {
            this.$router.push({
                name: nextStep,
                query: {
                    selectedService: this.selectedService,
                    selectedDataType: key,
                }
            });
        },
        dragover(e) {
            e.preventDefault();
            e.currentTarget.classList.add('dragover');
        },
        dragleave(e) {
            e.currentTarget.classList.remove('dragover');
        },
        drop(event) {
            const droppedFiles = Array.from(event.dataTransfer.files);
            droppedFiles.forEach(file => {
                if (this.validateFile(file)) {
                    if (this.fileExists(file)) {
                        this.showAlert(`File already exists: ${file.name}`);
                        return;
                    }
                    this.files.push({ file, name: file.name, type: file.type, size: file.size, status: 'Pending' });
                    this.totalSize += file.size;
                } else {
                    this.showAlert(`Invalid file type for ${this.selectedDataType} data: ${file.name}`);
                }
            });
            event.currentTarget.classList.remove('dragover');
        },

        validateFile(file) {
            if (this.selectedDataType === 'raw') {
            return file.name.endsWith('.fastq.gz') || file.name.endsWith('.fq.gz');
            } else if (this.selectedDataType === 'mapping') {
            return file.name.endsWith('.bam');
            } else if (this.selectedDataType === 'variant') {
            return file.name.endsWith('.vcf.gz');
            }
            return false;
        },

        fileExists(file) {
            const existFile = this.files.some(f => f.name === file.name);
            if (existFile) {
                return true;
            }
            return false;
        },

        selectFiles() {
            this.$refs.fileInput.value = '';
            this.$refs.fileInput.click();
        },

        addFilesByClickMultiple(event){
            const selectedFiles = Array.from(event.target.files);
            const duplicateFiles = selectedFiles.filter(file => this.fileExists(file));
            if (duplicateFiles.length > 0) {
                this.showAlert(`File already exists: ${duplicateFiles.map(f => f.name).join(', ')}`);
                return;
            }

            selectedFiles.forEach(file => {
            if (this.validateFile(file)) {
                this.files.push({ file, name: file.name, type: file.type, size: file.size, status: 'Pending' });
                this.totalSize += file.size;
            } else {
                this.showAlert(`Invalid file type for ${this.selectedDataType} data: ${file.name}`);
            }
            });
        },

        addFilesByClickSingle(event) {
            const selectedFile = event.target.files[0];
            if (this.files.length > 0) {
                this.showAlert('Only one file is allowed for this data type');
                return;
            }
            if (this.validateFile(selectedFile)) {
                this.files.push({ file: selectedFile, name: selectedFile.name, type: selectedFile.type, size: selectedFile.size, status: 'Pending' });
                this.totalSize += selectedFile.size;
            } else {
                this.showAlert(`Invalid file type for ${this.selectedDataType} data: ${selectedFile.name}`);
            }
        },

        isGoogleDriveLink(link){
            // const regex = new RegExp('^(https://drive.google.com/).*(file/d/|open\\?id=)(.*?)(/|$)');
            const regex = new RegExp('^(https://drive.google.com/drive/folders/)(.*?)(/|$)');
            return regex.test(link);
        },

        addGoogleDriveLink() {
            if (this.googleDriveLink === '' || !this.isGoogleDriveLink(this.googleDriveLink)) {
                this.showAlert('Please enter a valid google drive link');
                return;
            }
            this.files.push({ name: this.googleDriveLink, type: 'link', size: 0, status: 'Pending' });
            this.googleDriveLink = '';
        },

        cancelUpload(index) {
            const file = this.files[index];
            if (file.status === 'Uploading') {
                this.showAlert(`Cannot cancel upload for ${file.name} while uploading`);
                return;
            }
            this.files.splice(index, 1);
            this.totalSize -= file.size;
        },

        showAlert(message){
            this.popupMessage = message;
            this.popupType = 'alert';
            this.showPopup = true;
        },

        closePopup(){
            this.showPopup = false;
        },

        resetComponent(){
            this.files = [];
            this.successfulUploads = 0;
            this.totalSize = 0;
            this.popupMessage = '';
            this.popupType = '';
            this.showPopup = false;
        },

        validateFiles() {
            if (this.files.length === 0) {
                this.showAlert('Please upload subject data');
                return false;
            }

            let regex = '';
            if (this.selectedDataType === 'raw') {
                regex = regex = new RegExp('^(.*_L[1-9][0-9]*_[12].(fastq.gz|fq.gz))$');
            }

            const fileMap = new Map();
            for (let i = 0; i < this.files.length; i++) {
                const file = this.files[i];
                if (file.type === 'link') {
                    continue;
                }
                if (regex !== '' && !regex.test(file.name)) {
                    this.showAlert(`Please rename the file, ${file.name} to this format: "SampleName_LaneNumber_ReadPair.fastq.gz or fq.gz"`);
                    return false;
                }

                const match = file.name.match(/^(.*_L[1-9][0-9]*_)([12]).(fastq.gz|fq.gz)$/);
                if (match) {
                    const key = match[1];
                    const pair = match[2];
                    if (!fileMap.has(key)) {
                        fileMap.set(key, new Set());
                    }
                    fileMap.get(key).add(pair);
                }
            }

            for (const [key, pairs] of fileMap.entries()) {
                if (!pairs.has('1') || !pairs.has('2')) {
                    this.showAlert(`Files for ${key} are not properly paired. Please ensure both pairs (1 and 2) are uploaded.`);
                    return false;
                }
            }

            return true;
        },

        validateSubmision(){
  
            if (this.serviceType === '' || this.serviceTypes.indexOf(this.serviceType) === -1) {
                this.showAlert('Please select service type');
                return false;
            }
            if (
                this.firstname === '' || 
                this.lastname === '' || 
                this.sex === '' ||
                this.dateOfBirth === '' ||
                this.country === '' ||
                this.patientID === ''
            ) {
                this.showAlert('Please fill all the subject information');
                return false;
            }
            if (this.sex != 'Male' && this.sex != 'Female') {
                this.showAlert('Please enter subject\' gender');
                return false;
            }
            // check the format of the date of birth: dd/mm/yyyy
            const dobRegex = new RegExp('^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/[0-9]{4}$');
            if (!dobRegex.test(this.dateOfBirth)) {
                this.showAlert('Please enter a valid date of birth in the format dd/mm/yyyy');
                return false;
            }
            return this.validateFiles();
        },

        async uploadFiles() {
            let failedUploads = 0;

            // Create a Json file storing all info and upload it to the storage
            this.jsonInfoObject = {
                "personalInfo": {
                    "Patient Name": this.firstname + ' ' + this.lastname,
                    "Patient ID": this.patientID,
                    "Date of Birth": this.dateOfBirth,
                    "Sex": this.sex,
                    "Family History": this.familyHistory === '' ? 'Healthy' : this.familyHistory,
                    "Nationality": this.country,
                    "Sample ID": '',
                },
                "uploadFileInfo": {
                    files: this.files.map(file => {
                        return {
                            name: file.name,
                            type: file.type,
                            size: file.size,
                        };
                    }),
                    // readable time
                    uploadTime: this.uploadTime.toISOString(),
                    completed: false,
                    failedUploads: 0,
                },
                "serviceInfo": {
                    "service": this.selectedService,
                    "serviceType": this.serviceType,
                    "dataType": this.selectedDataType,
                    "path": `users/${this.userid}/${this.lastname}_${this.firstname}/${this.selectedService}/${String(this.formattedUploadTime)}/`,
                }
            }

            const storagePath = `users/${this.userid}/${this.lastname}_${this.firstname}/${this.selectedService}/${String(this.formattedUploadTime)}/`;
            const uploadPromises = this.files.map((fileData, index) => {
                return new Promise((resolve, reject) => {
                    if (fileData.type === "link") {
                        fileData.status = "Uploaded";
                        resolve();
                    } else {
                        const file = fileData.file;
                        const filename = file.name;

                        const storageReference = storageRef(
                            storage,
                            storagePath + filename
                        );

                        const uploadTask = uploadBytesResumable(storageReference, file);

                        uploadTask.on(
                            "state_changed",
                            (snapshot) => {
                                const progress =
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                fileData.status = `Uploading: ${progress.toFixed(2)}%`;
                            },
                            (error) => {
                                if (error.code != "storage/canceled") {
                                    console.error("stateChanged", error);
                                }
                                failedUploads++;
                                reject(error);
                            },
                            () => {
                                fileData.status = "Uploaded";
                                resolve();
                            }
                        );
                    }
                });
            });

            

            await Promise.allSettled(uploadPromises)
                .then((results) => {
                    if (failedUploads > 0) {
                        console.log("failedUploads", failedUploads);
                        this.showAlert(
                            `${failedUploads} files failed to upload. Please try again`
                        );
                        this.resetComponent();
                        this.jsonInfoObject.uploadFileInfo.completed = false;
                        this.jsonInfoObject.uploadFileInfo.failedUploads = failedUploads;
                    }
                    this.files.forEach((file) => {
                        if (file.status !== "Uploaded") {
                            console.error("uploadFiles", "Some files failed to upload");
                            return;
                        }
                    });
                    this.updateCreditsAndSubmissionsAfterUpload();
                    console.log("results", results);
                })
                .catch((error) => {
                    console.error("uploadFiles", error);
                    this.showAlert("An error occurred while uploading files");
                    this.resetComponent();
                });
        },

        async updateCreditsAndSubmissionsAfterUpload() {
            const userDoc = doc(db, "users", this.userid);
            const userDocSnap = await getDoc(userDoc);
            const userDocData = userDocSnap.data();

            const newCredits = userDocData.credits - this.creditsEstimation();

            await setDoc(userDoc, { credits: newCredits }, { merge: true });

            this.files.forEach((file) => {
                if (file.type === "link") {
                    return;
                }
                const filename = file.file.name;
                file.file = filename;
            });
            const submissionDoc = doc(db, "users", this.userid, "submissions", this.formattedUploadTime);
            const subjectData = {
                projectName: this.selectedService + " - " + this.serviceType + " - " + this.selectedDataType + " - " + this.formattedUploadTime,
                firstname: this.firstname,
                lastname: this.lastname,
                patientID: this.patientID,
                sex: this.sex,
                dateOfBirth: this.dateOfBirth,
                country: this.country,
                familyHistory: this.familyHistory === '' ? 'Healthy' : this.familyHistory,
                files: this.files,
                serviceInfo: {
                    service: this.selectedService,
                    serviceType: this.serviceType,
                    dataType: this.selectedDataType,
                    path: `users/${this.userid}/${this.lastname}_${this.firstname}/${this.selectedService}/${String(this.formattedUploadTime)}/`,
                },
                timestamp: this.uploadTime,
                status: "Processing",
            };
            await setDoc(submissionDoc, subjectData);
        },

        creditsEstimation() {
            let creditsRequired = 0;
            if (this.selectedServiceType == 'Gen-Health') {
                creditsRequired = 1.5;
            } else if (this.selectedServiceType == 'Gen-Pro') {
                creditsRequired = 2.5;
            }
            // if (this.selectedDataType === "raw" || this.selectedDataType === "mapping") {
            //     creditsRequired = 1;
            // } else if (this.selectedDataType === "variant") {
            //     creditsRequired = 1;
            // }
            return creditsRequired;
        },

        async submit() {
            if (!this.validateSubmision()) {
                return;
            }
            const creditsRequired = this.creditsEstimation();
            if (this.userCredits < creditsRequired) {
                this.showAlert('Insufficient credits to submit data. Now go back to the submission page.');
                setTimeout(() => {
                    this.$router.push('/data-submit');
                }, 3000);
                return;
            }
            this.isUploading = true;
            this.uploadTime = new Date();
            this.formattedUploadTime = this.uploadTime.toISOString().replace(/:/g, "-");
            await this.uploadFiles();
            // Convert the object to a blob
            this.jsonInfoObject.uploadFileInfo.completed = true;
            const jsonBlob = new Blob([JSON.stringify(this.jsonInfoObject)], { type: 'application/json' });
            const jsonFilename = "patientInfo.json";

            const storagePath = `users/${this.userid}/${this.lastname}_${this.firstname}/${this.selectedService}/${String(this.formattedUploadTime)}/`;
            const storageReference = storageRef(
                storage,
                storagePath + jsonFilename
            );

            const uploadTask = uploadBytesResumable(storageReference, jsonBlob);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    console.log("Uploading: Json info file");
                },
                (error) => {
                    if (error.code != "storage/canceled") {
                        console.error("stateChanged", error);
                    }
                    console.error("Json file failed to upload");
                },
                () => {
                    console.log("Json file uploaded successfully");
                }
            );
            this.isUploading = false;
            this.popupMessage = 'Data submitted successfully! Now go back to the submission page.';
            this.popupType = 'success';
            this.showPopup = true;
            setTimeout(() => {
                this.resetComponent();
                this.$router.push('/data-submit');
            }, 5000);
        }
    },
    computed: {
        selectedService() {
            return this.$route.query.selectedService;
        },
        selectedServiceType() {
            return this.$route.query.serviceType;
        },
        selectedDataType() {
            return this.$route.query.dataType;
        },
        selectedDataTypeTitle() {
            if (this.selectedDataType === 'raw') {
                return 'Raw Data';
            } else if (this.selectedDataType === 'mapping') {
                return 'Mapping Data';
            } else if (this.selectedDataType === 'variant') {
                return 'Variant Data';
            }
        },
        selectedDataTypeDescription() {
            if (this.selectedDataType === 'raw') {
                return 'Raw data\'s file format is .fastq.gz or .fq.gz, with approximately 30GB in size. You can upload directly the raw data or put the raw data in a google drive and share the link with us.';
            } else if (this.selectedDataType === 'mapping') {
                return 'Mapping data\'s file format is .bam, with approximately 30GB in size. You can upload directly the mapping data or put the mapping data in a google drive and share the link with us.';
            } else if (this.selectedDataType === 'variant') {
                return 'Variant data\'s file format is .vcf.gz, with approximately 1GB in size.';
            }
        },
        selectedDataTypeWarning() {
            let warning = '';
            if (this.selectedDataType === 'raw') {
                warning = 
                `
                <b>Notice</b>: <br>
                1. Please rename the files to this format: "SampleName_LaneNumber_ReadPair.fastq.gz or fq.gz"<br>
                    &emsp;a. LaneNumber should be L1, L2, L3, etc.<br>
                    &emsp;b. ReadPair should be 1 or 2.<br>
                    &emsp;c. Example: "Sample1_L1_1.fq.gz"<br>
                2. You always need to upload 2 read_pairs (i.e. 1 and 2) for each lane.<br>
                3. All files present in the Google Drive folder link(s) provided will be treated as normal files as though they had been uploaded through the Longevity Omics platform. When uploading a Google Drive link, please ensure that link corresponds to a folder link containing all files you wish to upload.
                `;
            }
            else if (this.selectedDataType === 'mapping') {
                warning = 
                `
                <b>Notice</b>: <br>
                1. You can only upload 1 file. <br>
                2. All files present in the Google Drive folder link(s) provided will be treated as normal files as though they had been uploaded through the Longevity Omics platform. When uploading a Google Drive link, please ensure that link corresponds to a folder link containing all files you wish to upload. <br>
                3. Please make sure the bam file is mapped to hg38 reference, otherwise, the submission will be rejected.
                `;
            }
            else if (this.selectedDataType === 'variant') {
                warning = 
                `
                <b>Notice</b>: <br>
                1. You can only upload 1 file. <br>
                2. Please make sure the vcf file is mapped to hg38 reference, otherwise, the submission will be rejected.
                `;
            }
            
            return warning;
        },
        selectedServiceCredits() {
            if (this.selectedServiceType == 'Gen-Health') {
                return 1.5;
            } else if (this.selectedServiceType == 'Gen-Pro') {
                return 2.5;
            }
            // if (this.selectedDataType === 'raw' || this.selectedDataType === 'mapping') {
            //     return 1;
            // } else if (this.selectedDataType === 'variant') {
            //     return 1;
            // }
        },
        supportedFile() {
            if (this.selectedDataType === 'raw') {
                return '.fastq.gz or .fq.gz';
            } else if (this.selectedDataType === 'mapping') {
                return '.bam';
            } else if (this.selectedDataType === 'variant') {
                return '.vcf.gz';
            }
        },
        approxFileSize() {
            if (this.selectedDataType === 'raw' || this.selectedDataType === 'mapping') {
                return 'Approximately 30GB in size';
            } else if (this.selectedDataType === 'variant') {
                return 'Approximately 1GB in size';
            }
        },
    },
};
</script>

<style scoped>
.red {
    color: red;
}

.text-wrap {
    word-break: break-all;
}

.on-top {
    z-index: 1000;
}

.data-upload-warning {
    color: #0398e2 !important;
}
</style>