/* This File is for storing the latest updates
    - The data is stored in an array of objects
    - Each object has a title, description, and icon
    - The icon is an iconify icon: https://icon-sets.iconify.design/
*/

export const newFeatures = [
    {
        icon: 'icon-park-solid:split',
        title: 'Gen-Health Release',
        description: 'The new version of Gen-Decoder is now available: Fully automated report generation'
    },
    {
        icon: 'mdi:professional-hexagon',
        title: 'Gen-Pro Release',
        description: 'The professional version of Gen-Decoder is now available: Advanced data analysis with Rare Disease Analysis'
    },
    {
        icon: 'fa:line-chart',
        title: 'Gen-Health -> Gen-Pro',
        description: 'Upgrade is now available: You can choose any version according to your needs and switch at any time'
    },
    {
        icon: 'mdi:cast-tutorial',
        title: 'Data Submission Tutorial',
        description: 'Data submission tutorial is now available: Learn how to submit data to Gen-Decoder'
    }
]