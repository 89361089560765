<template>
    <div>
        <SideBar />
        <GuidanceArea />
    </div>
</template>

<script>
import SideBar from '../dashboard/SideBar';
import GuidanceArea from '../guidance/GuidanceArea.vue';


export default {
    name: 'Guidance',
    components: {
        SideBar,
        GuidanceArea
    }
}
</script>