<template>
    <transition name="window">
    <div class="window-container" v-if="showModal">
        <!-- 遮罩层 -->
        <div class="window-overlay" @click="closeModal"></div>
        <!-- 弹窗主体 -->
        <div class="window-content">
            <!-- 关闭按钮 -->
            <button @click="closeModal" class="window-close">
                <i class="fas fa-times"></i>
            </button>
            <!-- 欢迎标题 -->
            <div class="window-header">
                <h2>Welcome Back,<br> {{ userName }}</h2>
                <p>This is our latest update for you</p>
            </div>
            <!-- 最近活动 -->
            <!-- <div class="modal-section">
                <h3>最近活动</h3>
                <div class="activities-list">
                    <div v-for="(activity, index) in recentActivities" :key="index" class="activity-item">
                        <i :class="activity.icon"></i>
                        <span>{{ activity.text }}</span>
                    </div>
                </div>
            </div> -->
            <!-- 新功能介绍 -->
            <div class="window-section">
                <h3>New Features Introduction</h3>
                <div class="features-grid">
                    <div v-for="(feature, index) in updatedFeatures" :key="index" class="feature-card">
                        <iconify-icon class="iconify" :icon="feature.icon"></iconify-icon>
                        <h4>{{ feature.title }}</h4>
                        <p>{{ feature.description }}</p>
                    </div>
                </div>
            </div>
            <!-- 按钮区域 -->
            <div class="window-footer">
                <button @click="closeModal" class="btn btn-secondary !rounded-button whitespace-nowrap">
                Ignore
                </button>
                <button @click="viewDetails" class="btn btn-primary !rounded-button whitespace-nowrap">
                View Details
                </button>
            </div>
        </div>
    </div>
    </transition>
</template>
<script>
import { auth, db } from "@/firebase";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { ref } from 'vue'
import { newFeatures } from './NewInfoUpdate'

// const recentActivities = ref([
//     { icon: 'fas fa-file-alt', text: '完成了 3 个重要文档的编辑' },
//     { icon: 'fas fa-users', text: '与团队成员进行了 2 次在线会议' },
//     { icon: 'fas fa-tasks', text: '完成了本周 85% 的任务目标' }
// ])



export default {
    methods: {
        viewDetails() {
            this.$router.push('/guidance')
        }
    },
    setup() {
        const showModal = ref(true)
        const userProfile = ref(null)
        const userName = ref('')
        const updatedFeatures = ref(newFeatures)
        const closeModal = () => {
            showModal.value = false
        }

        

        onAuthStateChanged(auth, (user) => {
            if (user) {
                const userRef = doc(db, "users", user.uid);
                if (userRef) {
                    getDoc(userRef).then((doc) => {
                        if (doc.exists()) {
                            userProfile.value = doc.data();
                            userName.value = userProfile.value.firstname;
                        }
                    });
                }
            }
        });


        return {
            showModal,
            userName,
            updatedFeatures,
            closeModal
        }
    }
}
</script>
<style lang="scss" scoped>
@keyframes modal-enter {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes modal-leave {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.9);
        opacity: 0;
    }
}

.window-enter-active {
    animation: modal-enter 0.3s ease-out;
}

.window-leave-active {
    animation: modal-leave 0.3s ease-in;
}

.window-container {
    position: fixed;
    inset: 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    
    .window-overlay {
        position: fixed;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s;
        z-index: 10000;
    }
    .window-content {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
        width: 640px;
        position: relative;
        z-index: 15000;
        transition: all 0.3s ease-in-out;
    }
    .window-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        color: #6b7280;
        transition: color 0.3s;
        i {
            font-size: 1.25rem;
        }
        &:hover {
            color: #374151;
        }
    }
    .window-header {
        padding: 2rem 2rem 1.5rem;
        h2 {
            font-size: 1.5rem;
            font-weight: 700;
            color: #1f2937;
        }
        p {
            color: #4b5563;
            margin-top: 0.5rem;
        }
    }
    .window-section {
        padding: 1rem 2rem;
        border-top: 1px solid #f3f4f6;
        h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: #374151;
            margin-bottom: 1rem;
        }
    }
    .window-footer {
        padding: 1.5rem 2rem;
        border-top: 1px solid #f3f4f6;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }
    &-enter-active,
    &-leave-active {
        transition: opacity 0.3s ease;
    }
    &-enter-from,
    &-leave-to {
        opacity: 0;
    }
}
.activities-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .activity-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        color: #4b5563;
        i {
            color: #3b82f6;
        }
    }
}
.features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    .feature-card {
        padding: 1rem;
        background-color: #f9fafb;
        border-radius: 0.5rem;
        .iconify {
            color: #3b82f6;
            font-size: 1.25rem;
            margin-bottom: 0.5rem;
        }
        h4 {
            font-weight: 500;
            color: #1f2937;
            margin-bottom: 0.25rem;
        }
        p {
            font-size: 0.875rem;
            color: #4b5563;
        }
    }
}
.btn {
    padding: 0.5rem 1.5rem;
    transition: background-color 0.3s;
    white-space: nowrap;
    &-primary {
        background-color: #3b82f6;
        color: white;
        &:hover {
            background-color: #2563eb;
        }
    }
    &-secondary {
        background-color: #f3f4f6;
        color: #4b5563;
        &:hover {
            background-color: #e5e7eb;
        }
    }
}
</style>
    