<template>
    <div>
        <SideBar />
        <DataSubmitAreaNav />
        <form class="feedback-wrapper" novalidate>
            <div class="inner-wrapper">
                <div class="feedback-area">
                <!-- <div class="container"> -->
                    <div class="feedback-title">
                        <h2 v-if="selectedService==='Gen-Decoder'">Select Your Gen-Decoder Type for {{ selectedService }}</h2>
                    </div> 
                    <div class="types" v-if="selectedService==='Gen-Decoder'">
                        <div class="columns">
                            <div
                                v-for="(type, index) in wgsTypes"
                                :key="index"
                                class="column is-6"
                            >
                                <div class="feedback-card">
                                    <img :src="type.image" alt="image" style="max-height: 180px;">
                                    <h3>{{ type.title }}</h3>
                                    <p v-html="type.description"></p>
                                    <div class="button-wrapper">
                                        <button 
                                            class="button is-primary v-button is-rounded is-bold is-elevated" 
                                            @click.prevent="goToLink(type.nextStep, type.key)">
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>     
                    <div class="types" v-else>
                        <ComingSoonArea />
                    </div>
                    <div class="feedback-buttons">
                        <div class="feedback-buttons-inner">
                            <router-link class="button v-button is-light" to="/data-submit/service-selection">Previous</router-link>
                            <router-link class="button v-button is-primary" to="/data-submit">Cancel</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import DataSubmitAreaNav from './DataSubmitAreaNav.vue';
import SideBar from '../dashboard/SideBar.vue';
import ComingSoonArea from './ComingSoonArea.vue';

export default {
    name: 'ServiceType',
    data() {
        return {
            wgsTypes: [
                {
                    title: 'Gen-Health',
                    description: '<strong style="color: black;"><i>Gen-Health</i></strong> offers a insights into age-associated diseases, pharmacogenomic recommendations for drugs, and personalized data on lifespan, nutrient absorption, diet preferences, appearance, and physical fitness. The report is normally delivered online within <strong style="color: red;"><i>6 hours</i></strong>「for 30x whole genome sequencing」.',
                    image: require('@/assets/images/automation.png'),
                    key: 'Gen-Health',
                    nextStep: 'DataSelection',
                },
                {
                    title: 'Gen-Pro',
                    description: '<strong style="color: black;"><i>Gen-Pro</i></strong> is a comprehensive genomic product, including <strong style="color: black;">all</strong> the features in <strong style="color: black;">Gen-Health</strong> and offering additional genomic and mitochondrial screening for monogenic diseases, evaluating inheritable cancer risks. The report is delivered online, with turnaround times typically ranging from <strong style="color: red;"><i>a few days to a week</i></strong>, depending on case complexity.',
                    image: require('@/assets/images/professional-development.png'),
                    key: 'Gen-Pro',
                    nextStep: 'DataSelection',
                },
            ],
        };
    },
    components: {
        DataSubmitAreaNav,
        SideBar,
        ComingSoonArea,
    },
    methods: {
        goToLink(nextStep, key) {
            this.$router.push({
                name: nextStep,
                query: {
                    selectedService: this.selectedService,
                    serviceType: key,
                }
            });
        },
    },
    computed: {
        selectedService() {
            return this.$route.query.selectedService;
        },
    },
};
</script>