<template>
<div class="wrapper">
    <!-- 欢迎区域 -->
    <DashboardArea />
    <!-- 导航区域 -->
    <div class="nav-tabs">
        <div class="tabs-container">
            <button
                v-for="tab in tabs"
                :key="tab.id"
                @click="activeTab = tab.id"
                :class="['tab-btn', { 'active': activeTab === tab.id }]"
                >
                    <i :class="['tab-icon', tab.icon]"></i>
                {{ tab.name }}
            </button>
        </div>
    </div>
    <!-- 内容区域 -->
    <div class="content-area">
    <!-- 最新功能 -->
        <div v-if="activeTab === 'features'" class="features-grid">
            <div v-for="feature in features" :key="feature.id" class="feature-card">
            <img :src="feature.image" :alt="feature.title" class="feature-image">
            <div class="feature-content">
                <h3 class="feature-title">{{ feature.title }}</h3>
                <p class="feature-desc">{{ feature.description }}</p>
                <button class="learn-more-btn" @click="learnMoreButton(feature.learnMore)">
                    Learn More
                    <i class="fas fa-arrow-right"></i>
                </button>
            </div>
            </div>
        </div>
        <!-- 操作指南 -->
        <div v-if="activeTab === 'guide'" class="guide-grid">
            <div class="guide-steps">
                <h3 class="guide-title">快速入门</h3>
                <div v-for="(step, index) in guideSteps"
                    :key="index"
                    class="step-item"
                    :class="{ 'active': currentStep === index }"
                    @click="currentStep = index">
                    <span class="step-number">{{ index + 1 }}</span>
                    <span class="step-title">{{ step.title }}</span>
                </div>
            </div>
            <div class="guide-content">
                <div v-if="currentStep !== null">
                    <h4 class="guide-content-title">{{ guideSteps[currentStep].title }}</h4>
                    <img :src="guideSteps[currentStep].image" :alt="guideSteps[currentStep].title" class="guide-image">
                    <p class="guide-text">{{ guideSteps[currentStep].content }}</p>
                </div>
            </div>
        </div>
        <!-- 视频教程 -->
        <div v-if="activeTab === 'videos'" class="videos-grid">
            <div v-for="video in videos" :key="video.id" class="video-card">
                <div class="video-thumbnail-wrapper">
                    <img :src="video.thumbnail" :alt="video.title" class="video-thumbnail">
                    <button class="play-btn" @click="playVideo(video)">
                        <i class="fas fa-play"></i>
                    </button>
                </div>
                <div class="video-info">
                    <h3 class="video-title">{{ video.title }}</h3>
                    <div class="video-meta">
                        <span><i class="far fa-clock"></i>{{ video.duration }}</span>
                        <span class="separator">|</span>
                        <span>{{ video.date }}</span>
                    </div>
                </div>
                <!-- Modal for video player -->
                <div v-if="videoPlay" class="video-play">
                    <div class="video-content">
                        <span class="close" @click="closeVideo">&times;</span>
                        <video :src="selectedVideo" controls autoplay style="width: 100%; height: 100%;">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <!-- 常见问题 -->
        <div v-if="activeTab === 'faq'" class="faq-section">
            <div v-for="(faq, index) in faqs"
                :key="index"
                class="faq-item"
                :class="{ 'active': activeFaq === index }">
                <div class="faq-header" @click="toggleFaq(index)">
                    <h3 class="faq-question">{{ faq.question }}</h3>
                    <i class="fas" :class="activeFaq === index ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                </div>
                <div v-show="activeFaq === index" class="faq-content">
                    <p>{{ faq.answer }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
    
<script>
import { ref } from 'vue';
import DashboardArea from '../dashboard/DashboardArea.vue';

export default {
    methods: {
        learnMoreButton(learnMoreAction) {
            if (learnMoreAction.type === 'goto') {
                this.$router.push(learnMoreAction.url);
            } else if (learnMoreAction.type === 'tabChange') {
                this.activeTab = learnMoreAction.url;
            }
        }
            
    },
    components: {
        DashboardArea
    },
    setup() {
        const activeTab = ref('features');
        const currentStep = ref(0);
        const activeFaq = ref(null);
        const videoPlay = ref(false);
        const selectedVideo = ref(null);

        const tabs = [
            { id: 'features', name: 'New Features', icon: 'fas fa-star' },
            // { id: 'guide', name: 'Guidances', icon: 'fas fa-book' },
            { id: 'videos', name: 'Video Tutorials', icon: 'fas fa-play-circle' },
            { id: 'faq', name: 'Q&A', icon: 'fas fa-question-circle' }
        ];
        const features = [
        {
            id: 1,
            title: 'Gen-Health Release',
            description: 'Fully automatic Gen-Health report generation, providing analysis and insights for patients and doctors around 6 hours after the sample is received.',
            image: 'https://ai-public.mastergo.com/ai/img_res/3219f698281ab2aa23015a797614d529.jpg',
            learnMore: {
                'type': 'goto',
                'url': '/data-submit'
            }
        },
        {
            id: 2,
            title: 'Gen-Pro Release',
            description: 'Pro version of Gen-Health, including all features of Gen-Health, and adding more advanced and professional disease analysis.',
            image: 'https://ai-public.mastergo.com/ai/img_res/66c98a851e5ffbe7e6eeedb7195e0873.jpg',
            learnMore: {
                'type': 'goto',
                'url': '/data-submit'
            }
        },
        {
            id: 3,
            title: 'Gen-Health Upgrade to Gen-Pro',
            description: 'Gen-Health reports can upgrade to Gen-Pro at any time.',
            image: 'https://www.bridgeheadsoftware.com/wp-content/uploads/2018/05/Upgrade-or-Not-Upgrade.jpg',
            learnMore: {
                'type': 'goto',
                'url': '/data-submit'
            }
        },
        {
            id: 4,
            title: 'Data Submission Tutorial',
            description: 'A detailed guide video on how to submit data to the system.',
            image: 'https://ai-public.mastergo.com/ai/img_res/5943e34c7b7d8f0daf9224bad5e749a4.jpg',
            learnMore: {
                'type': 'tabChange',
                'url': 'videos'
            }
        }
        
        ];
        const guideSteps = [
        {
        title: '创建新项目',
        content: '点击右上角的"新建项目"按钮，填写项目基本信息，包括项目名称、描述等。系统会自动为您创建项目空间。',
        image: 'https://ai-public.mastergo.com/ai/img_res/2ee39a337fe36507dc9dfa34ec2e5237.jpg'
        },
        {
        title: '团队管理',
        content: '在项目设置中添加团队成员，设置角色和权限，确保团队协作顺畅进行。',
        image: 'https://ai-public.mastergo.com/ai/img_res/0c8ab9d2e15d3818613704158227d188.jpg'
        },
        {
        title: '数据导入',
        content: '支持多种格式数据导入，包括 Excel、CSV 等。只需简单几步即可完成数据迁移。',
        image: 'https://ai-public.mastergo.com/ai/img_res/0c99d0121cbf481b4a7bb89169970bc6.jpg'
        }
        ];
        const videos = [
            // {
            // id: 1,
            // title: '快速入门教程',
            // duration: '15:30',
            // date: '2024-01-15',
            // thumbnail: 'https://ai-public.mastergo.com/ai/img_res/c178733a3fbaadceaa5d1476ce08144a.jpg'
            // },
            // {
            // id: 2,
            // title: '高级功能详解',
            // duration: '20:45',
            // date: '2024-01-16',
            // thumbnail: 'https://ai-public.mastergo.com/ai/img_res/0fbcf30fab7c7dd4df8d97c765c0ad21.jpg'
            // },
            {
                id: 1,
                title: 'Data Sumbission Instruction',
                duration: '1:44',
                date: '2025-03-07',
                thumbnail: 'https://ai-public.mastergo.com/ai/img_res/9087f5da86a9dcae6516a9531787d1eb.jpg',
                videoUrl: 'data_submission_instruction.mp4'
            }
        ];
        const faqs = [
            {
                question: 'How can I use this platform?',
                answer: 'First, join the waiting list or contact us directly. After your account is active, you can start creating projects and uploading data.'
            },
            {
                question: 'What data formats are supported for import?',
                answer: 'For Gen-Decoder, you can choose different data: Raw data: fastq.gz or fq.gz; Mapping data: .bam; Variant data: .vcf.gz.'
            }
        ];
        const playVideo = (video) => {
            videoPlay.value = true;
            selectedVideo.value = video.videoUrl;
            console.log(selectedVideo.value);
        };
        const closeVideo = () => {
            videoPlay.value = false;
            selectedVideo.value = null;
        };
        const toggleFaq = (index) => {
            activeFaq.value = activeFaq.value === index ? null : index;
        };
        return {
            activeTab,
            currentStep,
            activeFaq,
            videoPlay,
            selectedVideo,
            tabs,
            features,
            guideSteps,
            videos,
            faqs,
            playVideo,
            closeVideo,
            toggleFaq
        };
    }
};
</script>
    
 <style lang="scss" scoped>
    $primary-color: #00C48C;
    $secondary-color: #1E2875;
    $gray-light: #f8f9fa;
    $gray-medium: #666;
    $shadow-light: 0 2px 4px rgba(0, 0, 0, 0.05);
    $shadow-medium: 0 4px 12px rgba(0, 0, 0, 0.1);
    
    .wrapper {
      min-height: 100vh;
      background-color: #f9fafb;
    }
    
    .nav-tabs {
      max-width: 1440px;
      margin: 0 auto 2rem;
    
      .tabs-container {
        display: flex;
        justify-content: center;
        gap: 2rem;
      }
    
      .tab-btn {
        padding: 0.75rem 1.5rem;
        color: $secondary-color;
        background: transparent;
        border: none;
        border-radius: 0.375rem;
        position: relative;
        transition: all 0.3s ease;
        
        &.active {
          color: $primary-color;
          
          &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $primary-color;
          }
        }
      }
    
      .tab-icon {
        margin-right: 0.5rem;
      }
    }
    
    .content-area {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 2rem;
    }
    
    .features-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5rem;
    }
    
    .feature-card {
      background: white;
      border-radius: 0.5rem;
      overflow: hidden;
      transition: all 0.3s ease;
      box-shadow: $shadow-light;
    
      &:hover {
        transform: translateY(-4px);
        box-shadow: $shadow-medium;
      }
    
      .feature-image {
        width: 100%;
        height: 12rem;
        object-fit: cover;
      }
    
      .feature-content {
        padding: 1.5rem;
      }
    
      .feature-title {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    
      .feature-desc {
        color: #4b5563;
        margin-bottom: 1rem;
        min-height: 80px;
      }
    }
    
    .learn-more-btn {
      color: $primary-color;
      background: transparent;
      border: 1px solid $primary-color;
      padding: 0.5rem 1rem;
      border-radius: 0.375rem;
      transition: all 0.3s ease;
    
      &:hover {
        background: $primary-color;
        color: white;
      }
    
      i {
        margin-left: 0.5rem;
      }
    }
    
    .guide-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
    
    .guide-steps {
      background: white;
      border-radius: 0.5rem;
      padding: 1.5rem;
      box-shadow: $shadow-light;
    
      .guide-title {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }
    }
    
    .step-item {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      margin-bottom: 0.5rem;
      border-radius: 0.375rem;
      cursor: pointer;
      transition: all 0.3s ease;
    
      &:hover {
        background: $gray-light;
      }
    
      &.active {
        background: #e8f7f3;
        color: $primary-color;
      }
    
      .step-number {
        width: 1.5rem;
        height: 1.5rem;
        background: $primary-color;
        color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.75rem;
      }
    }
    
    .guide-content {
      background: white;
      border-radius: 0.5rem;
      padding: 1.5rem;
    
      .guide-content-title {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }
    
      .guide-image {
        width: 100%;
        height: 16rem;
        object-fit: cover;
        border-radius: 0.5rem;
        margin-bottom: 1rem;
      }
    
      .guide-text {
        color: #4b5563;
      }
    }
    
    .videos-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5rem;
    }
    
    .video-card {
      background: white;
      border-radius: 0.5rem;
      overflow: hidden;
      box-shadow: $shadow-light;
    
      .video-thumbnail-wrapper {
        position: relative;
      }
    
      .video-thumbnail {
        width: 100%;
        height: 12rem;
        object-fit: cover;
      }
    
      .video-info {
        padding: 1rem;
      }
    
      .video-title {
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    
      .video-meta {
        display: flex;
        align-items: center;
        color: #6b7280;
        font-size: 0.875rem;
    
        .separator {
          margin: 0 0.5rem;
        }
      }
    }
    
    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3rem;
      height: 3rem;
      background: rgba(0, 196, 140, 0.9);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
    
      &:hover {
        background: $primary-color;
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
    
    .faq-section {
      .faq-item {
        background: white;
        border-radius: 0.5rem;
        margin-bottom: 1rem;
        box-shadow: $shadow-light;
        overflow: hidden;
    
        &.active .faq-header {
          background: $gray-light;
          border-bottom: 1px solid #eee;
        }
      }
    
      .faq-header {
        padding: 1rem 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;
    
        &:hover {
          background: $gray-light;
        }
    
        .faq-question {
          font-size: 1.125rem;
          font-weight: 600;
        }
      }
    
      .faq-content {
        padding: 0 1.5rem 1rem;
        color: $gray-medium;
      }
    }
    .video-play {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .video-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
        height: auto;
        position: relative;
    }

    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
    </style>