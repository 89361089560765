<template>
    <div>
        <SideBar />
        <DataSubmitAreaNav />
        <form class="feedback-wrapper" novalidate>
            <div class="inner-wrapper">
                <div class="feedback-area">
                <!-- <div class="container"> -->
                    <div class="feedback-title">
                        <h2>Select Your Service Type</h2>
                    </div> 
                    <div class="types">
                        <div class="columns">
                            <div
                                v-for="(type, index) in feedbackTypes"
                                :key="index"
                                class="column is-4"
                            >
                                <div class="feedback-card">
                                    <img :src="type.image" alt="image">
                                    <h3>{{ type.title }}</h3>
                                    <p>{{ type.description }}</p>
                                    <div class="button-wrapper">
                                        <button 
                                            class="button is-primary v-button is-rounded is-bold is-elevated" 
                                            @click.prevent="goToLink(type.nextStep, type.title)">
                                            Continue
                                        </button>
                                    </div>
                                    <div class="learn-more-link">
                                        <router-link
                                            class="router-link"
                                            :to="type.learnMore"
                                        >
                                            or Learn More
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>           
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import DataSubmitAreaNav from './DataSubmitAreaNav.vue';
import SideBar from '../dashboard/SideBar.vue';

export default {
    name: 'ServiceSelection',
    data() {
        return {
            feedbackTypes: [
                {
                    title: 'Gen-Decoder',
                    description: 'Gen-Decoder provides comprehensive data analysis and reporting services for human whole genome sequencing, tailored for longevity clinicians and medical centres.',
                    image: require('@/assets/images/main-banner3.jpg'),
                    learnMore: '/Gen-Decoder',
                    nextStep: 'ServiceTypeSelection',
                },
                {
                    title: 'Epi-Insight',
                    description: 'Epi-Insight offers advanced tools for analyzing epigenetic markers related to aging, with a focus on precise epigenetic aging clock, aging acceleration and system age predictions.',
                    image: require('@/assets/images/dna1.jpg'),
                    learnMore: '/Epi-Insight',
                    nextStep: 'DataSelection',
                },
                {
                    title: 'Epi-Tracker',
                    description: 'Epi-Tracker offers advanced tools for analyzing genetic variations and associated epigenetic changes, supporting personalized health monitoring, disease prevention, and targeted therapies.',
                    image: require('@/assets/images/dna4.jpg'),
                    learnMore: '/Epi-Tracker',
                    nextStep: 'DataSelection',
                },
            ],
        };
    },
    components: {
        DataSubmitAreaNav,
        SideBar,
    },
    methods: {
        goToLink(nextStep, type) {
            this.$router.push({
                name: nextStep,
                query: {
                    selectedService: type
                }
            });
        },
    },
};
</script>