<template>
    <div>
        <InfoWindow />
        <SideBar />
        <DashboardArea />
        <Profile />
    </div>
</template>

<script>
import SideBar from '../dashboard/SideBar';
import DashboardArea from '../dashboard/DashboardArea';
import Profile from '../profile/Profile.vue';
import InfoWindow from '../info-window/InfoWindow.vue';


export default {
    name: 'Dashboard',
    components: {
        SideBar,
        DashboardArea,
        Profile,
        InfoWindow
    }
}
</script>